import { useContext, useEffect } from "react";
import AuthContext from "@/hooks/AuthContext";
import { ProtectedRoute } from "@/components/ProtectedRoute";
import { Button } from "@/components/ui/button";
import Header from "@/components/ui/header";
import { Link } from "react-router-dom";
import { getExamPersonalData } from "@/lib/api/getProfile";
import { getTransactionData } from "@/lib/api/getTransaction";

import { useQueries } from "@tanstack/react-query";
import { Edit, FileText, Landmark, Loader, Megaphone } from "lucide-react";
import {
  getRangeEditProfile,
  getRangePayment,
  getRangeShowExamRoom,
  getRangeShowScoreResult,
} from "@/lib/api/getTimeSwitch";
import Swal from "sweetalert2";
import { reTransaction } from "@/lib/api/repostTransaction";
interface UserInnerData {
  id?: number;
  student_identityid_type?: null;
  student_identityid?: string;
  student_prefix?: string;
  student_firstname?: string;
  student_surname?: string;
  student_birthday?: string;
  student_level?: string;
  student_school?: string;
  student_school_subdistrict?: number;
  student_house_no?: string;
  student_village_no?: string;
  student_addr_alley?: string;
  student_addr_lane?: string;
  student_addr_street?: string;
  student_addr_subdistrict?: number;
  student_mobile?: string;
  parent1_prefix?: string;
  parent1_firstname?: string;
  parent1_surname?: string;
  parent1_relationship?: string;
  parent1_mobile?: string;
  parent2_prefix?: string;
  parent2_firstname?: string;
  parent2_surname?: string;
  parent2_relationship?: string;
  parent2_mobile?: string;
  teacher_prefix?: string;
  teacher_firstname?: string;
  teacher_surname?: string;
  teacher_mobile?: string;
}
interface UserData {
  data?: UserInnerData;
  success?: boolean;
}

interface Auth {
  user: UserData | null;
  signin: (idcard: string, date: string | undefined) => Promise<void>;
  signout: () => void;
  loading: boolean;
}

export default function Profile() {
  const auth = useContext<Auth | null>(AuthContext);

  const [
    examPersonalDataQuery,
    transactionQuery,
    canEditProfile,
    canPaid,
    canAccessRoom,
    scoreAnnounceRange,
  ] = useQueries({
    queries: [
      { queryKey: ["examData"], queryFn: getExamPersonalData },
      {
        queryKey: ["transactionData"],
        queryFn: getTransactionData,
      },
      {
        queryKey: ["editProfile"],
        queryFn: getRangeEditProfile,
      },
      {
        queryKey: ["rangePayment"],
        queryFn: getRangePayment,
      },
      {
        queryKey: ["canAccessRoom"],
        queryFn: getRangeShowExamRoom,
      },
      {
        queryKey: ["scoreAnnounceRange"],
        queryFn: getRangeShowScoreResult,
        staleTime: 1000 * 60 * 60 * 24,
      },
    ],
  });

  useEffect(() => {
    if (transactionQuery.data?.data?.data?.status == "cancel") {
      Swal.fire({
        title: "ใบแจ้งชำระเงินหมดอายุแล้ว",
        text: "ต้องการสมัครใหม่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ต้องการ",
        cancelButtonText: "กลับหน้าหลัก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await reTransaction();
          window.location.reload();
        }
        if (result.isDismissed) {
          window.location.href = "/";
        }
      });
    }
  }, [transactionQuery.data?.data?.data?.status]);

  return (
    <ProtectedRoute>
      <div className="grid gap-4">
        <div className="flex flex-col">
          <div className="relative flex flex-col gap-2">
            <Header text="ข้อมูลส่วนบุคคล | Personal Profile" />
            {/* personal-profile-card */}
            <div
              id="personal-profile-card"
              className="flex flex-col p-4 place-self-center justify-center items-center gap-2 border-[1.5px] rounded-lg border-black md:w-3/4"
            >
              <img className="w-3/4" src="pprofile.svg" alt="profile" />
              <p className="bg-yellow-200 p-2 rounded-lg">
                <span className="font-bold">หมายเลขสมัครสอบ</span>
                {" " + examPersonalDataQuery?.data?.data.data?.reg_no_id}
              </p>
              <p className="text-xl font-bold">
                {auth?.user?.data?.student_prefix}{" "}
                {auth?.user?.data?.student_firstname}{" "}
                {auth?.user?.data?.student_surname}
              </p>
              <p>
                ชั้นประถมศึกษาปีที่{" "}
                {auth?.user?.data?.student_level?.match(/.$/)}
              </p>
              <p>
                โรงเรียน
                {auth?.user?.data?.student_school?.replace("โรงเรียน", "")}
              </p>
              <p className="bg-purple-200 p-2 rounded-lg flex items-center gap-2">
                <span className="font-bold">สถานะการสมัครสอบ</span>
                {transactionQuery.status === "loading" ? (
                  <span className="w-28 h-4 bg-purple-500 rounded animate-pulse" />
                ) : transactionQuery?.data?.data?.data?.status === "paid" ? (
                  "ชำระเงินแล้ว"
                ) : transactionQuery?.data?.data?.data?.status === "unpaid" ? (
                  "รอการชำระเงิน"
                ) : transactionQuery?.data?.data?.data?.status ===
                  "verifying" ? (
                  "รอการตรวจสอบการชำระเงิน"
                ) : (
                  "ยังไม่ได้สมัครสอบ"
                )}
              </p>
              <div className="flex w-full justify-between my-4">
                <Link
                  to={
                    canEditProfile?.data?.["data"]?.["config"]
                      ? "/editProfile"
                      : "#"
                  }
                  className="place-self-end"
                >
                  <Button
                    className="flex gap-2 bg-orange-300 hover:bg-orange-200 text-black active:bg-orange-400"
                    disabled={!canEditProfile?.data?.["data"]?.["config"]}
                  >
                    <Edit />
                    แก้ไขข้อมูล
                  </Button>
                </Link>

                {transactionQuery?.isLoading ? (
                  <Loader className="place-self-end animate-spin text-sky-300" />
                ) : transactionQuery?.data?.data?.data?.status === "paid" ? (
                  <Link
                    to={
                      canAccessRoom?.data?.["data"]?.["config"]
                        ? "/payment"
                        : "#"
                    }
                    className="place-self-end"
                  >
                    <Button
                      className="flex gap-2 bg-sky-300 hover:bg-sky-200 text-black active:bg-sky-400"
                      disabled={!canAccessRoom?.data?.["data"]?.["config"]}
                    >
                      <FileText />
                      รายละเอียดห้องสอบ
                    </Button>
                  </Link>
                ) : (
                  <Link
                    to={canPaid?.data?.["data"]?.["config"] ? "/payment" : "#"}
                    className="place-self-end"
                  >
                    <Button
                      className="flex gap-2 bg-sky-300 hover:bg-sky-200 text-black active:bg-sky-400"
                      disabled={!canEditProfile?.data?.["data"]?.["config"]}
                    >
                      <Landmark />
                      ชำระเงิน
                    </Button>
                  </Link>
                )}
              </div>
            </div>
            <Link
              to={"/announcement"}
              className="w-full md:w-3/4 place-self-center"
            >
              <Button
                className="w-full flex gap-2 bg-primary hover:bg-primary/80 text-black active:bg-primary/90"
                // a condition that check this can be announce
                disabled={!scoreAnnounceRange?.data?.["data"]?.["config"]}
              >
                <Megaphone />
                ประกาศผลทดสอบ
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
