import axfetch from "../axfetch";

export const idcheck = (idcard: string) => {
  return axfetch.post("/api/users/checker", {
    idcard: idcard,
  });
};

export const login = (idcard: string, date: string | undefined) => {
  return axfetch.post(
    "/api/users/login",
    {
      idcard: idcard,
      date: date,
    }
  );
};
