import Header from "@/components/ui/header";
import TestStatusCard from "./ui/testStatusCard";
import { useQuery } from "@tanstack/react-query";
import { getContent } from "@/lib/api/getWebpage";
import { Loader } from "lucide-react";

const TestStatus = () => {
  const { data: news, isLoading } = useQuery(["news"], getContent, {
    staleTime: 1000 * 60 * 60 * 24,
  });
  return (
    <div>
      <Header text="สถานะในการเปิดรับสมัคร" />
      <div className="mt-4 flex flex-col gap-4 min-h-[50px] overflow-auto">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Loader
              size={64}
              className="motion-safe:animate-spin text-fuchsia-300"
            />
          </div>
        ) : (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          news?.data.schedule.map((input: any) => {
            return (
              <TestStatusCard
                key={input.index}
                text={input.content}
                date={
                  new Date(input.startdate).toLocaleDateString("th-TH", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }) +
                  " - " +
                  new Date(input.enddate).toLocaleDateString("th-TH", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default TestStatus;
