import { Button } from "@/components/ui/button";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const DatePicker = ({
  id,
  placeHolder,
  onChange,
  date = undefined,
  isRequired,
  isDisable,
  variant = "outline",
  fromYear,
  toYear,
}: {
  id: string;
  placeHolder: string;
  onChange?: (date: Date | undefined) => void;
  date?: Date | undefined;
  isRequired: boolean;
  isDisable: boolean;
  fromYear?: number;
  toYear?: number;
  variant:
    | "link"
    | "default"
    | "gray"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "datePicker"
    | null
    | undefined;
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={isDisable}
          variant={variant}
          className={cn(
            "w-full justify-start text-left font-normal border-2 border-black",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            date.toLocaleDateString("th-TH", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          ) : (
            <span>{placeHolder}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          required={isRequired}
          id={id}
          mode="single"
          selected={date}
          onSelect={onChange}
          fromYear={fromYear}
          toYear={toYear}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePicker;
