import axfetch from "../axfetch";

export const getProfile = () => {
  return axfetch.get(`/api/users/data/`, {
    withCredentials: true,
  });
};

export const getExamPersonalData = () => {
  return axfetch.get(`/api/examdata`, {
    withCredentials: true,
  });
}