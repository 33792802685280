import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import logo from "@/assets/logo.svg";
import { useState, useContext, useEffect } from "react";
import AuthContext from "@/hooks/AuthContext";
import {
  AlertTriangle,
  AlignJustify,
  FileEdit,
  FileText,
  Headphones,
  HelpCircle,
  Home,
  Landmark,
  LogIn,
  LogOut,
  PersonStanding,
  ScanFace,
  UserCircle2,
  X,
  Megaphone,
} from "lucide-react";
import {
  getRangeRegister,
  getRangeShowScoreResult,
} from "@/lib/api/getTimeSwitch";
import { useQueries } from "@tanstack/react-query";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { getTransactionData } from "@/lib/api/getTransaction";

interface UserData {
  data?: {
    id?: number;
    student_identityid_type?: null;
    student_identityid?: string;
    student_prefix?: string;
    student_firstname?: string;
    student_surname?: string;
    student_birthday?: string;
    student_level?: string;
    student_school?: string;
    student_school_subdistrict?: number;
    student_house_no?: string;
    student_village_no?: string;
    student_addr_alley?: string;
    student_addr_lane?: string;
    student_addr_street?: string;
    student_addr_subdistrict?: number;
    student_mobile?: string;
    parent1_prefix?: string;
    parent1_firstname?: string;
    parent1_surname?: string;
    parent1_relationship?: string;
    parent1_mobile?: string;
    parent2_prefix?: string;
    parent2_firstname?: string;
    parent2_surname?: string;
    parent2_relationship?: string;
    parent2_mobile?: string;
    teacher_prefix?: string;
    teacher_firstname?: string;
    teacher_surname?: string;
    teacher_mobile?: string;
  };
  success?: boolean;
}

interface Auth {
  user: UserData | object | null;
  signin: (idcard: string, date: string | undefined) => Promise<void>;
  signout: () => void;
  loading: boolean;
}

function Layout() {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const auth = useContext<Auth | null>(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [register, transactionData, scoreAnnounceRange] = useQueries({
    queries: [
      {
        queryKey: ["register"],
        queryFn: getRangeRegister,
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["transactionData"],
        queryFn: getTransactionData,
        enabled: auth != null && auth.user != null,
      },
      {
        queryKey: ["scoreAnnounceRange"],
        queryFn: getRangeShowScoreResult,
        staleTime: 1000 * 60 * 60 * 24,
      },
    ],
  });

  useEffect(() => {
    setIsVisible(false);
  }, [location]);

  return (
    <div className="flex flex-col">
      {/* Body */}
      <div id="detail-section" className="container mx-auto my-6 mt-52">
        <Outlet />
      </div>
      <div className="fixed w-full z-50">
        <div className="bg-slate-50 z-50 flex gap-4 md:gap-6 items-center p-2">
          <Link to="/">
            <img
              src={logo}
              alt="strisrinan logo"
              width={"100"}
              height={"100"}
              className="min-w-[100px] min-h-[100px]"
            />
          </Link>
          <div className="flex flex-col gap-1">
            <div className="text-[#329902] text-2xl sm:text-3xl font-bold">
              ระบบสอบวัดความรู้ โรงเรียนสตรีศรีน่าน
            </div>
            <div className="text-md sm:text-xl">Strisrinan Academic Test</div>
          </div>
        </div>

        {/* Navigation bar */}
        <div className="flex flex-col md:items-center gap-y-2 divide-y divide-white sm:divide-transparent sm:flex-row p-3 lg:px-4 text-xs md:text-sm lg:text-md w-full justify-between bg-primary text-white">
          <div className="flex sm:hidden justify-between items-center">
            <button
              onClick={() => setIsVisible(!isVisible)}
              data-collapse-toggle="divbar-default"
              type="button"
              className="text-lg sm:hidden w-fit inline-flex p-2 rounded-lg hover:bg-white hover:bg-opacity-20"
              aria-controls="navbar-default"
              aria-expanded="false"
            >
              <span className="sr-only">เปิดเมนูหลัก</span>
              {!isVisible ? <AlignJustify /> : <X />}
            </button>
            {auth && auth.user != null ? (
              <Popover>
                <PopoverTrigger asChild>
                  <p
                    className="flex gap-2 p-2 hover:bg-white/20 rounded-md items-center"
                  >
                    <UserCircle2 />
                    {auth && auth.user != null
                      ? (auth.user as UserData)?.data?.student_firstname +
                        " " +
                        (auth.user as UserData)?.data?.student_surname
                      : ""}
                  </p>
                </PopoverTrigger>
                <PopoverContent className="w-30">
                  <div className="grid gap-4">
                    <div className="space-y-2">
                      <Link
                        to="/profile"
                        className="text-xs flex gap-2 justify-center items-center"
                      >
                        <PersonStanding className="w-4" />
                        ข้อมูลส่วนบุคคล
                      </Link>
                      <button
                        onClick={async () => {
                          await auth?.signout();
                          navigate("/Register");
                        }}
                        className="text-xs flex gap-2 justify-center items-center"
                      >
                        <LogOut className="w-4" />
                        ออกจากระบบ
                      </button>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            ) : (
              <Link
                to="/Register"
                className="flex gap-2 p-2 hover:bg-white/20 rounded-md"
              >
                <LogIn />
                เข้าสู่ระบบ
              </Link>
            )}
          </div>
          {/* Desktop navigation bar */}
          <div className="hidden sm:flex sm:flex-row gap-2">
            <Link
              className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
              to="/"
            >
              <Home className="hidden lg:block" />
              หน้าหลัก
            </Link>
            {!auth?.user && (
              <Popover>
                <Link
                  className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                  to={register?.data?.["data"]?.["config"] ? "/register" : "#"}
                >
                  <PopoverTrigger className="w-full h-full flex gap-2 items-center">
                    <FileEdit className="hidden lg:block" />
                    สมัครสอบ
                  </PopoverTrigger>
                </Link>
                {!register?.data?.["data"]?.["config"] ? (
                  <PopoverContent className="w-fit bg-yellow-100 flex gap-2">
                    <AlertTriangle className="text-yellow-500 drop-shadow-[0_1px_0px_rgba(0,0,0,1)]" />
                    ไม่เปิดรับสมัคร
                  </PopoverContent>
                ) : (
                  <></>
                )}
              </Popover>
            )}
            {auth?.user && (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                to="/profile"
              >
                <PersonStanding className="hidden lg:block" />
                ข้อมูลส่วนบุคคล
              </Link>
            )}
            {auth?.user &&
              (transactionData?.data?.data.data.status === "paid" ? (
                <Link
                  className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                  to="/payment"
                >
                  <FileText className="hidden lg:block" />
                  รายละเอียดห้องสอบ
                </Link>
              ) : (
                <Link
                  className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                  to="/payment"
                >
                  <Landmark className="hidden lg:block" />
                  แสดงข้อมูลชำระเงิน
                </Link>
              ))}
            {auth?.user && scoreAnnounceRange?.data?.["data"]?.["config"] && (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                to="/announcement"
              >
                <Megaphone className="hidden lg:block" />
                ประกาศผลทดสอบ
              </Link>
            )}
            <Link
              className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
              to="/contact"
            >
              <Headphones className="hidden lg:block" />
              ติดต่อสอบถาม
            </Link>
            <Link
              className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
              to="/faq"
            >
              <HelpCircle className="hidden lg:block" />
              Q&A
            </Link>
          </div>
          <div className="hidden sm:flex gap-2">
            {!auth?.user ? (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                to="/register"
              >
                <LogIn />
                เข้าสู่ระบบ
              </Link>
            ) : (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                to="#"
                onClick={async () => {
                  await auth?.signout();
                  navigate("/Register");
                }}
              >
                <LogOut />
                ออกจากระบบ
              </Link>
            )}
            {!auth?.user && (
              <a
                className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                href="/staff/signin"
              >
                <ScanFace />
                เข้าสู่ระบบเจ้าหน้าที่
              </a>
            )}
          </div>
        </div>
      </div>
      {/* Mobile navigation bar */}
      {
        <aside
          className={`fixed sm:hidden top-44 divide-y bg-primary text-white w-fit px-8 min-h-screen transition ${
            !isVisible ? `-translate-x-full` : ""
          } flex flex-col`}
        >
          <div className="sm:hidden flex flex-col sm:flex-row gap-2 w-full py-4">
            <Link
              className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
              to="/"
            >
              <Home />
              หน้าหลัก
            </Link>
            {!auth?.user && (
              <Popover>
                <Link
                  className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
                  to={register?.data?.["data"]?.["config"] ? "/register" : "#"}
                >
                  <PopoverTrigger className="w-full h-full flex gap-2 items-center">
                    <FileEdit />
                    สมัครสอบ
                  </PopoverTrigger>
                </Link>
                {!register?.data?.["data"]?.["config"] ? (
                  <PopoverContent className="w-fit bg-yellow-100 flex gap-2">
                    <AlertTriangle className="text-yellow-500 drop-shadow-[0_1px_0px_rgba(0,0,0,1)]" />
                    ไม่เปิดรับสมัคร
                  </PopoverContent>
                ) : (
                  <></>
                )}
              </Popover>
            )}
            {auth?.user && (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
                to="/profile"
              >
                <PersonStanding />
                ข้อมูลส่วนบุคคล
              </Link>
            )}
            {auth?.user &&
              (transactionData?.data?.data.data.status === "paid" ? (
                <Link
                  className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
                  to="/payment"
                >
                  <FileText />
                  รายละเอียดห้องสอบ
                </Link>
              ) : (
                <Link
                  className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
                  to="/payment"
                >
                  <Landmark />
                  แสดงข้อมูลชำระเงิน
                </Link>
              ))}
            {auth?.user && scoreAnnounceRange?.data?.["data"]?.["config"] && (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md p-1 py-1.5 sm:px-2 flex gap-2 items-center"
                to="/announcement"
              >
                <Megaphone />
                ประกาศผลทดสอบ
              </Link>
            )}
            <Link
              className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
              to="/contact"
            >
              <Headphones />
              ติดต่อสอบถาม
            </Link>
            <Link
              className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
              to="/faq"
            >
              <HelpCircle />
              คำถามที่พบบ่อย
            </Link>
          </div>
          <div className="sm:hidden flex flex-col md:flex-row gap-2 w-full py-4">
            {!auth?.user ? (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
                to="/register"
              >
                <LogIn />
                เข้าสู่ระบบ
              </Link>
            ) : (
              <Link
                className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
                to="#"
                onClick={async () => {
                  await auth?.signout();
                  navigate("/register");
                }}
              >
                <LogOut />
                ออกจากระบบ
              </Link>
            )}
            {!auth?.user && (
              <a
                className="hover:bg-white hover:bg-opacity-20 rounded-md py-1.5 px-2 flex gap-2 items-center"
                href="/staff/signin"
              >
                <ScanFace />
                เข้าสู่ระบบเจ้าหน้าที่
              </a>
            )}
          </div>
        </aside>
      }
    </div>
  );
}

export default Layout;
