import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function Combobox({
  datas,
  place,
  input,
  setInput,
}: {
  datas:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
  place: string;
  input: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInput: React.Dispatch<React.SetStateAction<any>>;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(input);
  const [isSet, setIsSet] = React.useState(0);

  React.useEffect(() => {
    if (isSet < 1 && input !== "") {
      setValue(input);
      setIsSet(isSet + 1);
    }
  }, [input, isSet]);

  // React.useEffect(() => {
  //   if (isSet == "") {
  //     setValue(input)
  //     setIsSet(input)
  //   }
  //   }, [input, isSet])

  return (
    <Popover modal={true} open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full border-2 border-black justify-between"
        >
          {value
            ? datas?.find((data) => data.value === value || data.label === value)?.label
            : `เลือก${place}...`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent side="bottom" className="w-[400px] p-0">
        <Command>
          <CommandInput placeholder={`เลือก${place}...`} />
          <ScrollArea className="h-72">
            <CommandEmpty>No {place} found.</CommandEmpty>
            <CommandGroup>
              {datas?.map((data) => (
                <CommandItem
                  key={data.value}
                  onSelect={() => {
                    setValue(data.value === value ? "" : data.value);
                    // input = data.value === value ? "" : data.value;
                    setInput(data.value === value ? "" : data.value);
                    // setValue(currentValue === value ? "" : currentValue)
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === data.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {data.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
