import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthContextProvider } from "./hooks/AuthContext";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
// import Enroll from "./pages/enroll";
import Register from "./pages/Register";
import Contact from "./pages/Contact";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import Payment from "./pages/Payment";
import FaQ from "./pages/FaQ";
import Announcement from "./pages/Announcement";

const BASE_URL = import.meta.env.VITE_BASE_URL || "/";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});
const router = createBrowserRouter(
  [
    {
      path: "",
      Component: Layout,
      children: [
        { path: "", element: <Home /> },
        { path: "/", element: <Home /> },
        // { path: "/enroll", element: <Enroll /> },
        { path: "/register", element: <Register /> },
        { path: "/contact", element: <Contact /> },
        { path: "/profile", element: <Profile /> },
        { path: "/editProfile", element: <EditProfile /> },
        { path: "/payment", element: <Payment /> },
        { path: "/faq", element: <FaQ /> },
        { path: "/announcement", element: <Announcement /> },
      ],
    },  
  ],
  { basename: BASE_URL }
);

function App() {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
