import News from "@/components/news";
import Advertise from "@/components/advertise";
import TestStatus from "@/components/testStatus";
import { useQueries } from "@tanstack/react-query";
import { getContent } from "@/lib/api/getWebpage";
import { getRangeDownloadExamCard, getRangeRegister, getRangeShowExamRoom, getRangeShowScoreResult } from "@/lib/api/getTimeSwitch";

import { Loader } from "lucide-react";
import StatusBanner from "@/components/statusBanner";
import { Link } from "react-router-dom";
// import { format } from "date-fns";
// import { useEffect, useState } from "react";

export default function Home() {
  // const { data: news, isLoading } = useQuery(["news"], getContent, {
  //   staleTime: 1000 * 60 * 60 * 24,
  // });
  const [news, timeline, examInfoRange, examTicketRange, scoreAnnounceRange] = useQueries({
    queries: [
      {
        queryKey: ["news"],
        queryFn: getContent,
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["timeline"],
        queryFn: getRangeRegister,
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["examInfoRange"],
        queryFn: getRangeShowExamRoom,
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["examTicketRange"],
        queryFn: getRangeDownloadExamCard,
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["scoreannounce"],
        queryFn: getRangeShowScoreResult,
        staleTime: 1000 * 60 * 60 * 24,
      }
    ],
  });

  // const [now, setDate] = useState(new Date());

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setDate(new Date());
  //   }, 1000);

  //   return () => {
  //     clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
  //   };
  // }, []);

  return (
    <div className="flex flex-col gap-4">
      {/* System Status */}
      <div className="flex">
        {timeline.isLoading ? (
          <div className="flex justify-center items-center">
            <Loader
              size={64}
              className="motion-safe:animate-spin text-blue-300 mt-20"
            />
          </div>
        ) : // <StatusBanner
        //   status={timeline?.data?.['data']?.['config']}
        //   option={"register"}
        // />

        // change to full button to Register use Router Link

        // now > new Date("2023-12-07T02:00:00Z") 

        //check if scoreAnnounceRange.data?.["data"]?.["config"] is true
        scoreAnnounceRange.data?.["data"]?.["config"] ? (
          <Link to={"/announcement"} className="flex w-full">
            <StatusBanner status={true} option={"scoreannounce"} />
          </Link>
        ) :

        // check if examTicketRange.data?.["data"]?.["config"] is true
        examTicketRange.data?.["data"]?.["config"] ? (
          <Link to={"/payment"} className="flex w-full">
            <StatusBanner status={true} option={"examcard"} />
          </Link>
        ) :

        // check if examInfoRange.data?.["data"]?.["config"] is true
        examInfoRange.data?.["data"]?.["config"] ? (
          <Link to={"/payment"} className="flex w-full">
            <StatusBanner status={true} option={"examroom"} />
          </Link>
        ) : (
          <Link
            to={timeline?.data?.["data"]?.["config"] ? "/register" : "#"}
            className="flex w-full"
          >
            <StatusBanner
              status={timeline?.data?.["data"]?.["config"]}
              option={"register"}
            />
          </Link>
        )}
      </div>

      {/* Announcement */}
      <div className="flex flex-col-reverse sm:flex-row justify-between gap-4">
        <div className="w-full overflow-auto">
          <News>
            {news.isLoading ? (
              <div className="flex justify-center items-center">
                <Loader
                  size={64}
                  className="motion-safe:animate-spin text-blue-300 mt-20"
                />
              </div>
            ) : (
              <div
                className="text-black"
                dangerouslySetInnerHTML={{
                  __html: String(news?.data?.data.webcontent?.[0].content),
                }}
              />
            )}
          </News>
        </div>
        <div className="w-full">
          <div className="sticky top-0 flex flex-col gap-2">
            <Advertise />
            <TestStatus />
          </div>
        </div>
      </div>
    </div>
  );
}
