import Header from "@/components/ui/header";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useQuery } from "@tanstack/react-query";
import { getContent } from "@/lib/api/getWebpage";
import { Loader } from "lucide-react";
// import { Link } from "react-router-dom";

export default function FaQ() {
  const { data: news, isLoading } = useQuery(["news"], getContent, {
    staleTime: 1000 * 60 * 60 * 24,
  });

  return (
    <div className="flex flex-col gap-4">
      <Header text="คำถามที่พบบ่อย | Frequently asked questions" />
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Loader
            size={64}
            className="motion-safe:animate-spin text-blue-300 mt-20"
          />
        </div>
      ) : (
        <div>
          {news?.data.question_answer
            .map((item: { question: string; answer: string }, i: number) => (
              <Accordion
                type="single"
                collapsible
                className="w-full bg-primary/10"
              >
                <AccordionItem value={String(i)} className="border-primary">
                  <AccordionTrigger className="bg-primary/40 p-4">
                    <div
                      className="text-black"
                      dangerouslySetInnerHTML={{
                        __html: String(item.question),
                      }}
                    />
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pt-4">
                    <div
                      className="text-black"
                      dangerouslySetInnerHTML={{
                        __html: String(item.answer),
                      }}
                    />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ))}
        </div>
      )}{" "}
    </div>
  );
}
