import axfetch from '../axfetch';

const data = new FormData();

export const uploadFile = (file: File) => {
    data.append('file', file);

    return axfetch.post('/api/slip/upload', data, {
        maxBodyLength: Infinity,
        withCredentials: true,
    });
}