import axfetch from "../axfetch";

interface UserInform {
  student_identityid_type: null;
  student_identityid: string;
  student_prefix: string;
  student_firstname: string;
  student_surname: string;
  student_birthday: string | undefined;
  student_level: string;
  student_school: string;
  student_school_subdistrict: string;
  student_house_no: string | undefined;
  student_village_no: string | undefined;
  student_addr_alley: string | undefined;
  student_addr_lane: string | undefined;
  student_addr_street: string | undefined;
  student_addr_subdistrict: string;
  student_mobile: string;
  parent1_prefix: string;
  parent1_firstname: string;
  parent1_surname: string;
  parent1_relationship: string;
  parent1_mobile: string;
  parent2_prefix: string;
  parent2_firstname: string;
  parent2_surname: string;
  parent2_relationship: string;
  parent2_mobile: string;
  teacher_prefix: string;
  teacher_firstname: string;
  teacher_surname: string;
  teacher_mobile: string;
}

export const updateUser = (data: UserInform) => {
  return axfetch.post(`/api/users/data`, {
    student_identityid_type: null,
    student_identityid: data.student_identityid,
    student_prefix: data.student_prefix,
    student_firstname: data.student_firstname,
    student_surname: data.student_surname,
    student_birthday: data.student_birthday,
    student_level: data.student_level,
    student_school: data.student_school,
    student_school_subdistrict: Number(data.student_school_subdistrict),
    student_house_no: data.student_house_no,
    student_village_no: data.student_village_no,
    student_addr_alley: data.student_addr_alley,
    student_addr_lane: data.student_addr_lane,
    student_addr_street: data.student_addr_street,
    student_addr_subdistrict: Number(data.student_addr_subdistrict),
    student_mobile: data.student_mobile,
    parent1_prefix: data.parent1_prefix,
    parent1_firstname: data.parent1_firstname,
    parent1_surname: data.parent1_surname,
    parent1_relationship: data.parent1_relationship,
    parent1_mobile: data.parent1_mobile,
    parent2_prefix: data.parent2_prefix,
    parent2_firstname: data.parent2_firstname,
    parent2_surname: data.parent2_surname,
    parent2_relationship: data.parent2_relationship,
    parent2_mobile: data.parent2_mobile,
    teacher_prefix: data.teacher_prefix,
    teacher_firstname: data.teacher_firstname,
    teacher_surname: data.teacher_surname,
    teacher_mobile: data.teacher_mobile,
  }, {
    withCredentials: true,
  });
};
