import axfetch from "../axfetch";

export const getCertificate = (exam_id: number) => {
    return axfetch.get(`/api/pdf/certificate/${exam_id}`, {
        withCredentials: true,
        responseType: "blob"
    });
};

export const isCertificateAvailable = (exam_id: number) => {
    return axfetch.get(`/api/pdf/check/certificate/${exam_id}`, {
        withCredentials: true,
    });
}