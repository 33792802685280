const Header = ({ text, color }: { text: string; color?: string }) => {
  return (
    <div className={`divide-y-4 ${color == undefined ? 'divide-primary' : `divide-${color}`} `}>
      <div className="flex">
        <span className={`bg-${color ? color : 'primary'} p-2 font-bold text-white`}>
          {text}
        </span>
      </div>
      <div></div>
    </div>
  );
};

export default Header;
