export default function Contact() {
  return (
    <div className="flex flex-col shadow-xl rounded-md">
      <div className="flex flex-col gap-2 p-6">
        <span className="font-bold text-2xl text-primary">
          กลุ่มบริการงานวิชาการ โรงเรียนสตรีศรีน่าน จังหวัดน่าน
        </span>
        <p>
          <span className="font-bold">ที่อยู่ : อาคาร 3 ชั้น 2 </span>{" "}
          เลขที่ 226/24 ถนนมะโน ตำบลในเวียง อำเภอเมืองน่าน จังหวัดน่าน 55000
        </p>
        <p>
          <span className="font-bold">โทรศัพท์ :</span> 086-420-4986
        </p>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7554.967527790809!2d100.78325434340823!3d18.776582346046165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31278e0a56cf794b%3A0x96b17e8a0027f63c!2z4LmC4Lij4LiH4LmA4Lij4Li14Lii4LiZ4Liq4LiV4Lij4Li14Lio4Lij4Li14LiZ4LmI4Liy4LiZ!5e0!3m2!1sth!2sth!4v1696270584022!5m2!1sth!2sth"
          className="w-full h-[300px]"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
