import { useEffect, useRef, useState } from "react";
// import AuthContext from "@/hooks/AuthContext";
import { ProtectedRoute } from "@/components/ProtectedRoute";
import { Button } from "@/components/ui/button";
import Header from "@/components/ui/header";
import { Input } from "@/components/ui/input";
import { Link, useNavigate } from "react-router-dom";
// import DatePicker from "@/components/ui/datepicker";
import { postTransaction } from "@/lib/api/postTransaction";
import { getExamPersonalData } from "@/lib/api/getProfile";
import { getTransactionData } from "@/lib/api/getTransaction";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Stepper } from "@/components/stepper";
import { useQueries } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { uploadFile } from "@/lib/api/uploadFile";
import {
  getRangeDownloadExamCard,
  getRangePayment,
} from "@/lib/api/getTimeSwitch";
import StatusBanner from "@/components/statusBanner";
import { Clipboard, DoorOpen, Loader } from "lucide-react";
import CopyToClipboard from "react-copy-to-clipboard";
import { reTransaction } from "@/lib/api/repostTransaction";
import axfetch from "@/lib/axfetch";
import { getExamTicket } from "@/lib/api/getExamTicket";
import { getRangeShowExamRoom } from "@/lib/api/getTimeSwitch";

// import TimeInput from "react-widgets/TimeInput";

interface Transaction {
  transaction_amount: number;
  transaction_url: string;
  transaction_date: Date | undefined;
  transaction_time: string;
  transaction_ref: string;
  transaction_id: number;
}

export default function Payment() {
  //   const auth = useContext<Auth | null>(AuthContext);
  // const account_number = "XXX-X-XXXXX-X";
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement | null>(null);
  // const [status, setTransactionStatus] = useState<string>("verifying");

  const [transaction, setTransaction] = useState<Transaction>({
    transaction_amount: 150.0,
    transaction_url: "",
    transaction_date: undefined,
    transaction_time: "",
    transaction_ref: "",
    transaction_id: -1,
  });

  const [transactionImage, setTransactionImage] = useState<string>("");

  const [
    examPersonalDataQuery,
    transactionQuery,
    payment,
    examcard,
    showExamRoom,
  ] = useQueries({
    queries: [
      { queryKey: ["examData"], queryFn: getExamPersonalData },
      {
        queryKey: ["transactionData"],
        queryFn: getTransactionData,
        onSuccess: (data: { data: { data: { transaction_id: number } } }) => {
          setTransaction({
            ...transaction,
            ["transaction_id"]: data?.data.data.transaction_id,
          });
          return data;
        },
      },
      {
        queryKey: ["payment"],
        queryFn: getRangePayment,
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["examcard"],
        queryFn: getRangeDownloadExamCard,
        staleTime: 1000 * 60 * 60 * 24,
      },
      {
        queryKey: ["showExamRoom"],
        queryFn: getRangeShowExamRoom,
        staleTime: 1000 * 60 * 60 * 24,
      },
    ],
  });

  const [examTicketQuery] = useQueries({
    queries: [
      {
        queryKey: ["examTicket", examPersonalDataQuery?.data?.data.data.id],
        queryFn: () => getExamTicket(examPersonalDataQuery?.data?.data.data.id),
        retry: 1,
        enabled: examcard?.data ? true : false,
        onSuccess: (data: { data: Blob }) => {
          document
            .querySelector("iframe")
            ?.setAttribute("src", window.URL.createObjectURL(data.data));
        },
      },
    ],
  });

  const handleCreateTransaction = async (
    e: React.SyntheticEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setButtonDisabled(true);
    if (transaction.transaction_id !== -1) {
      if (!fileRef || !fileRef.current?.files) throw new Error();
      // if (!transaction.transaction_date) {
      //   return Swal.fire({
      //     icon: "info",
      //     title: "กรุณากรอกข้อมูลให้ครบถ้วน",
      //     text: "กรุณากรอกวันที่โอน",
      //     confirmButtonText: "ตกลง",
      //   });
      // }
      await uploadFile(fileRef.current.files[fileRef.current.files.length - 1])
        .then((res) => {
          postTransaction({
            ...transaction,
            transaction_url: res.data?.filename,
          })
            .then((res) => {
              // console.log(res);
              if (res?.data.success === "true") {
                setButtonDisabled(false);
                Swal.fire({
                  icon: "success",
                  title: "สำเร็จ",
                  text: "ทำการแจ้งชำระเงินเรียบร้อย",
                  confirmButtonText: "ตกลง",
                }).then(() => navigate(0));
              } else {
                setButtonDisabled(false);
                Swal.fire({
                  icon: "error",
                  title: "เกิดข้อผิดพลาด",
                  text: "กรุณากรอกข้อมูลให้ถูกต้อง",
                  confirmButtonText: "ตกลง",
                });
              }
            })
            .catch(() => {
              setButtonDisabled(false);
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: "กรุณากรอกข้อมูลให้ถูกต้อง",
                confirmButtonText: "ตกลง",
              });
            });
        })
        .catch(() => {
          setButtonDisabled(false);
          Swal.fire("Error", "กรุณาตรวจสอบไฟล์หลักฐานการโอน", "error");
        });
    }
  };

  useEffect(() => {
    if (transactionQuery.data?.data?.data?.status == "cancel") {
      Swal.fire({
        title: "ใบแจ้งชำระเงินหมดอายุแล้ว",
        text: "ต้องการสมัครใหม่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ต้องการ",
        cancelButtonText: "กลับหน้าหลัก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await reTransaction()
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "สำเร็จ",
                text: "ทำการสมัครใหม่เรียบร้อย",
                confirmButtonText: "ตกลง",
              }).then(() => navigate(0));
            })
            .catch(() => {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: "กรุณาลองใหม่อีกครั้ง",
                confirmButtonText: "ตกลง",
              });
            });
          window.location.reload();
        }
        if (result.isDismissed) {
          window.location.href = "/";
        }
      });
    }
  }, [transactionQuery.data?.data?.data?.status]);

  useEffect(() => {
    // console.log(userTransaction);
    if (!transactionQuery.data?.data?.data?.transaction_url) return;
    axfetch
      .get(
        "/api/public/uploads/slip/" +
          String(transactionQuery?.data?.data?.data?.transaction_url),
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        setTransactionImage(URL.createObjectURL(res.data));
      });
  }, [transactionQuery.data?.data?.data]);

  // useEffect(() => {
  // console.log(transactionQuery?.data?.data.data.status);
  // }, [transactionQuery]);

  return (
    <ProtectedRoute>
      <div className="grid gap-8">
        {payment.isLoading ? (
          <>
            <div className="flex justify-center items-center">
              <Loader
                size={64}
                className="motion-safe:animate-spin text-yellow-300 mt-20"
              />
            </div>
          </>
        ) : !payment?.data?.["data"]?.["config"] ? (
          <></>
        ) : (
          <div className="w-full flex justify-center">
            <Stepper
              step={
                transactionQuery?.data?.data.data.status === "unpaid"
                  ? 1
                  : transactionQuery?.data?.data.data.status === "verifying"
                  ? 2
                  : 3
              }
            />
          </div>
        )}
        <div className="flex flex-col gap-4 items-center">
          {payment.isLoading ? (
            <div className="flex justify-center items-center">
              <Loader
                size={64}
                className="motion-safe:animate-spin text-sky-200 mt-20"
              />
            </div>
          ) : !showExamRoom?.data?.["data"]?.["config"] ? (
            <StatusBanner status={false} option="examroom" />
          ) : transactionQuery?.data?.data.data.status === "unpaid" ? (
            <div className="grid gap-4 max-w-3xl">
              {/* payment */}
              <div className="relative">
                <Header text="การชำระเงิน | Payment" />
                <p className="py-2 font-semibold text-center">
                  การชำระเงินผ่านการ Scan QR
                </p>

                <div className="grid py-2 gap-4 place-content-center">
                  {/* qr-code (left) */}
                  <div
                    id="payment-qr-code"
                    className="w-full gap-2 flex flex-col justify-center items-center max-w-lg"
                  >
                    {/* <div className="w-full h-96 bg-cyan-900 flex flex-col">
                  ที่ใส่ qr
                </div> */}
                    <img
                      src="/AcademicTestQRPayment.png"
                      alt="qr-code"
                      width={"80%"}
                    />
                    <Button
                      className="bg-sky-900 text-white text-lg hover:bg-sky-700"
                      onClick={() => {
                        window.open("/AcademicTestQRPayment.png", "_blank");
                      }}
                    >
                      ดาวน์โหลด QR-Code
                    </Button>
                    <div
                      id="description-payment"
                      className="flex flex-col gap-2 p-2 rounded-md border-black border-[1.5px] text-center"
                    >
                      <p className="text-lg">
                        <span className="font-bold">ชื่อบัญชี</span>{" "}
                        การสอบวัดความรู้วิชาการ (โรงเรียนสตรีศรีน่าน)
                      </p>
                      <p className="text-lg">
                        <span className="font-bold">สาขา</span>{" "}
                        {"0717 สาขาห้างนราไฮเปอร์มาร์ท"}
                      </p>
                      <CopyToClipboard text={"020426378483"}>
                        <p
                          className="text-lg flex gap-2 justify-center cursor-pointer"
                          onClick={() => {
                            Swal.mixin({
                              toast: true,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: 3000,
                              timerProgressBar: true,
                              didOpen: (toast) => {
                                toast.addEventListener(
                                  "mouseenter",
                                  Swal.stopTimer
                                );
                                toast.addEventListener(
                                  "mouseleave",
                                  Swal.resumeTimer
                                );
                              },
                            }).fire({
                              icon: "success",
                              title: "คัดลอกเลขที่บัญชีเรียบร้อย",
                              iconColor: "#69aadb",
                            });
                          }}
                        >
                          <span className="font-bold">เลขที่บัญชี</span>{" "}
                          {"020426378483"}
                          <Clipboard width={20} className="hover:opacity-50" />
                        </p>
                      </CopyToClipboard>
                      <p className="font-bold text-lg text-red-500">
                        {" "}
                        กรุณาพิมพ์ชื่อนักเรียนที่บันทึกช่วยจำ
                      </p>
                      <p className="text-lg">
                        <span className="font-bold">ธนาคาร</span> {"ออมสิน"}
                      </p>
                    </div>
                    <CopyToClipboard text={"020426378483"}>
                      <Button
                        className="w-full bg-zinc-300 text-black hover:bg-zinc-200 active:text-white"
                        type="button"
                        onClick={() => {
                          Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener(
                                "mouseenter",
                                Swal.stopTimer
                              );
                              toast.addEventListener(
                                "mouseleave",
                                Swal.resumeTimer
                              );
                            },
                          }).fire({
                            icon: "success",
                            title: "คัดลอกเลขที่บัญชีเรียบร้อย",
                            iconColor: "#69aadb",
                          });
                        }}
                      >
                        คัดลอกเลขที่บัญชี
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>

                <div className="grid grid-cols-3 w-full place-items-center">
                  <h1 className="font-bold text-lg">จำนวนเงิน</h1>
                  <p className="font-bold text-xl underline">
                    {transaction.transaction_amount}
                  </p>
                  <p className="font-bold text-lg">บาท</p>
                </div>

                {/* describe (right) */}
                <div className="flex flex-col gap-2">
                  {/* bf-time */}
                  <div
                    id="bf-time"
                    className="flex flex-col p-4 justify-center items-center gap-2 border-[1.5px] rounded-lg border-black"
                  >
                    <h1 className="text-lg font-bold">กรุณาแจ้งโอนก่อน</h1>
                    <div
                      className="text-red-500 text-center font-bold text-2xl"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {new Date(
                        new Date(
                          transactionQuery?.data?.data.data.createAt
                        ).getTime() + 86400000
                      )
                        .toLocaleString("th-TH")
                        .replace(" ", "\n") + ` น.`}
                    </div>
                    <div className="text-red-500 text-center">
                      หากไม่ชำระเงินในเวลาที่กำหนด
                      ระบบจะยกเลิกการสมัครโดยอัตโนมัติ
                    </div>
                  </div>
                </div>
              </div>
              {/* payment-form */}
              <div className="relative">
                <Header text="การแจ้งชำระเงิน | Payment Confirmation" />
                <form onSubmit={handleCreateTransaction} className="grid gap-4">
                  <div className="grid md:grid-cols-2 gap-x-10 gap-y-2 py-2">
                    <div>
                      <label htmlFor="transaction_amount" className="mb-2">
                        ยอดโอนเงิน (บาท){" "}
                        <span className="text-secondary">*</span>
                      </label>
                      <Input
                        name="transaction_amount"
                        id="transaction_amount"
                        required={true}
                        disabled={true}
                        className="border-2 border-black"
                        value={transaction.transaction_amount}
                        onChange={(e) =>
                          setTransaction({
                            ...transaction,
                            ["transaction_amount"]: Number(e.target.value),
                          })
                        }
                        type="number"
                        placeholder="กรอกจำนวนเงิน"
                      />
                    </div>
                    <div>
                      <label htmlFor="transaction_url" className="mb-2">
                        หลักฐานการโอน <span className="text-secondary">*</span>
                      </label>
                      <Input
                        name="transaction_url"
                        id="transaction_url"
                        required={true}
                        className="border-2 border-black"
                        ref={fileRef}
                        type="file"
                        placeholder="transaction_url"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                  <div className="w-full flex justify-center items-center mb-20">
                    <Button
                      className="w-full"
                      type="submit"
                      disabled={buttonDisabled}
                    >
                      ยืนยันการชำระเงิน
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          ) : transactionQuery?.data?.data.data.status === "verifying" ? (
            <>
              <div className="flex flex-col place-items-center gap-4">
                <img src={transactionImage} alt="transaction picture" />
                <div className="relative w-full">
                  <Header
                    text="แก้ไขหลักฐานการชำระเงิน | Edit Payment Slip"
                    color="orange-400"
                  />
                  <form
                    onSubmit={handleCreateTransaction}
                    className="grid gap-4"
                  >
                    <div className="grid md:grid-cols-2 gap-x-10 gap-y-2 py-2">
                      <div className="col-span-2">
                        <label htmlFor="transaction_url" className="mb-2">
                          หลักฐานการโอน{" "}
                          <span className="text-secondary">*</span>
                        </label>
                        <Input
                          name="transaction_url"
                          id="transaction_url"
                          required={true}
                          className="border-2 border-black"
                          ref={fileRef}
                          type="file"
                          placeholder="transaction_url"
                          accept="image/png, image/jpeg"
                        />
                      </div>
                    </div>
                    <div className="w-full flex justify-center items-center">
                      <Button
                        className="w-fit bg-orange-300 text-black hover:bg-orange-200 active:bg-orange-500"
                        type="submit"
                        disabled={buttonDisabled}
                      >
                        ยืนยันการแก้ไขหลักฐานการชำระเงิน
                      </Button>
                    </div>
                  </form>
                </div>
                <Link to="/profile" className="w-full flex justify-end">
                  <Button className="flex gap-2 items-center bg-sky-400 text-black hover:bg-sky-300">
                    <DoorOpen />
                    กลับไปหน้าหลัก
                  </Button>
                </Link>
              </div>
            </>
          ) : (
            <div>
              <div className="relative grid gap-2">
                <Header text="รายละเอียดห้องสอบ | Examiation Information" />
                <Table className="hidden lg:block">
                  <TableHeader>
                    <TableRow className="bg-primary bg-opacity-50">
                      <TableHead className="text-black">วันที่สอบ</TableHead>
                      <TableHead className="text-black">เวลาที่สอบ</TableHead>
                      {/* <TableHead className="text-black">
                        เลขที่ผู้สมัครสอบ
                      </TableHead> */}
                      <TableHead className="text-black">ชื่อห้องสอบ</TableHead>
                      <TableHead className="text-black">
                        เลขที่นั่งสอบ
                      </TableHead>
                      <TableHead className="text-black">ชั้นที่สอบ</TableHead>
                      <TableHead className="text-black">ตึกที่สอบ</TableHead>
                      <TableHead className="text-black">ระดับชั้น</TableHead>
                      <TableHead className="text-right text-black">
                        ดาวน์โหลดบัตรเข้าห้องสอบ
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">
                        {"6 มกราคม 2567"}
                      </TableCell>
                      <TableCell>{"9:00 น."}</TableCell>
                      {/* <TableCell>
                        {examPersonalDataQuery?.data?.data.data.reg_no_id}
                      </TableCell> */}
                      <TableCell>
                        {`${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.room_name} (${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.no})` ||
                          "รอการประกาศ"}
                      </TableCell>
                      <TableCell>
                        {examPersonalDataQuery?.data?.data.data.exam_id ||
                          "รอการประกาศ"}
                      </TableCell>
                      <TableCell>
                        {`${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.floor}` ||
                          "รอการประกาศ"}
                      </TableCell>
                      <TableCell>
                        {`${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.building}` ||
                          "รอการประกาศ"}
                      </TableCell>
                      <TableCell>
                        {`ประถมศึกษาปีที่ ${
                          examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.student_level.split(
                            "l"
                          )[2]
                        }` || "รอการประกาศ"}
                      </TableCell>

                      <TableCell className="text-right">
                        <Button
                          disabled={
                            examcard["data"]?.["data"]?.["config"]
                              ? (examTicketQuery?.data?.data ? false : true)
                              : true
                          }
                          onClick={() => {
                            console.log(examTicketQuery?.data?.data);
                            const url = window.URL.createObjectURL(
                              new Blob([examTicketQuery?.data?.data])
                            );
                            const link = document.createElement("a");
                            link.download = `บัตรเข้าห้องสอบ_${examPersonalDataQuery?.data?.data.data.exam_id}.pdf`;
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                          }}
                        >
                          {examcard.isLoading ? (
                            <Loader
                              size={10}
                              className="motion-safe:animate-spin text-white"
                            />
                          ) : (
                            "บัตรเข้าห้องสอบ"
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table className="lg:hidden">
                  <TableHeader>
                    <TableRow className="bg-primary bg-opacity-50">
                      <TableHead className="text-black">วันที่สอบ</TableHead>
                      <TableHead className="text-black">เวลาที่สอบ</TableHead>
                      <TableHead className="text-black">ชื่อห้องสอบ</TableHead>
                      <TableHead className="text-black">
                        เลขที่นั่งสอบ
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">
                        {"6 มกราคม 2567"}
                      </TableCell>
                      <TableCell>{"9:00 น."}</TableCell>
                      <TableCell>
                        {`${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.room_name} (${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.no})` ||
                          "รอการประกาศ"}
                      </TableCell>
                      <TableCell>
                        {examPersonalDataQuery?.data?.data.data.exam_id ||
                          "รอการประกาศ"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table className="lg:hidden">
                  <TableHeader>
                    <TableRow className="bg-primary bg-opacity-50">
                      {" "}
                      <TableHead className="text-black">ชั้นที่สอบ</TableHead>
                      <TableHead className="text-black">ตึกที่สอบ</TableHead>
                      <TableHead className="text-black">ระดับชั้น</TableHead>
                      <TableHead className="text-right text-black">
                        ดาวน์โหลดบัตรเข้าห้องสอบ
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {`${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.floor}` ||
                          "รอการประกาศ"}
                      </TableCell>
                      <TableCell>
                        {`${examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.building}` ||
                          "รอการประกาศ"}
                      </TableCell>
                      <TableCell>
                        {`ประถมศึกษาปีที่ ${
                          examPersonalDataQuery?.data?.data.data?.exam_room_exam_data_exam_roomToexam_room.student_level.split(
                            "l"
                          )[2]
                        }` || "รอการประกาศ"}
                      </TableCell>

                      <TableCell className="text-right">
                        <Button
                          disabled={
                            examcard["data"]?.["data"]?.["config"]
                              ? false
                              : true
                          }
                          onClick={() => {
                            console.log(examTicketQuery?.data?.data);
                            const url = window.URL.createObjectURL(
                              new Blob([examTicketQuery?.data?.data])
                            );
                            const link = document.createElement("a");
                            link.download = `ExamTicket-${examPersonalDataQuery?.data?.data.data.id}.pdf`;
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                          }}
                        >
                          {examcard.isLoading ? (
                            <Loader
                              size={10}
                              className="motion-safe:animate-spin text-white"
                            />
                          ) : (
                            "บัตรเข้าห้องสอบ"
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                {/* <Document  className={"w-full h-fit"} /> */}
                <iframe
                  width="100%"
                  height={examTicketQuery.data?.data ? "1000px" : "0"}
                  className={examcard["data"]?.["data"]?.["config"]
                  ? (examTicketQuery?.data?.data ? "" : "hidden")
                  : "hidden"}
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    </ProtectedRoute>
  );
}
