import { cn } from "@/lib/utils";

export const Stepper = ({
  step,
  className,
  ...props
}: {
  step: number;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col", className)} {...props}>
      <ol className="flex gap-8 sm:gap-4 md:gap-8 lg:gap-10 items-center w-full py-4 sm:space-y-0">
        <li className={`flex flex-col sm:flex-row items-center ${step == 1 ? `text-primary dark:text-primary` : `text-gray-500 dark:text-gray-400`} sm:space-x-2.5`}>
          <span className={`flex items-center justify-center w-8 h-8 border ${step == 1 ? `border-primary dark:border-primary` : `border-gray-500 dark:border-gray-400`} rounded-full shrink-0`}>
            1
          </span>
          <span className="flex flex-col items-center sm:items-start">
            <h3 className="flex gap-1 font-medium leading-tight"><span className="hidden sm:block">Payment</span>Waiting</h3>
            <p className="text-sm">รอการชำระเงิน</p>
          </span>
        </li>
        <li className={`flex flex-col sm:flex-row items-center ${step == 2 ? `text-primary dark:text-primary` : `text-gray-500 dark:text-gray-400`} sm:space-x-2.5`}>
          <span className={`flex items-center justify-center w-8 h-8 border ${step == 2 ? `border-primary dark:border-primary` : `border-gray-500 dark:border-gray-400`} rounded-full shrink-0`}>
            2
          </span>
          <span className="flex flex-col items-center sm:items-start">
            <h3 className="flex gap-1 font-medium leading-tight"><span className="hidden sm:block">Payment</span>Verifying</h3>
            <p className="flex text-sm">รอตรวจสอบ<span className="hidden sm:block">ใบแจ้งชำระ</span></p>
          </span>
        </li>
        <li className={`flex flex-col sm:flex-row items-center ${step == 3 ? `text-primary dark:text-primary` : `text-gray-500 dark:text-gray-400`} sm:space-x-2.5`}>
          <span className={`flex items-center justify-center w-8 h-8 border ${step == 3 ? `border-primary dark:border-primary` : `border-gray-500 dark:border-gray-400`} rounded-full shrink-0`}>
            3
          </span>
          <span className="flex flex-col items-center sm:items-start">
            <h3 className="flex gap-1 font-medium leading-tight"><span className="hidden sm:block">Payment</span>Successful</h3>
            <p className="flex text-sm"><span className="hidden sm:block">การชำระเงิน</span>เสร็จสมบูรณ์</p>
          </span>
        </li>
      </ol>
    </div>
  );
};
