import AuthContext from "@/hooks/AuthContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

interface UserData {
  data?: {
    id?: number;
    student_identityid_type?: null;
    student_identityid?: string;
    student_prefix?: string;
    student_firstname?: string;
    student_surname?: string;
    student_birthday?: string;
    student_level?: string;
    student_school?: string;
    student_school_subdistrict?: number;
    student_house_no?: string;
    student_village_no?: string;
    student_addr_alley?: string;
    student_addr_lane?: string;
    student_addr_street?: string;
    student_addr_subdistrict?: number;
    student_mobile?: string;
    parent1_prefix?: string;
    parent1_firstname?: string;
    parent1_surname?: string;
    parent1_relationship?: string;
    parent1_mobile?: string;
    parent2_prefix?: string;
    parent2_firstname?: string;
    parent2_surname?: string;
    parent2_relationship?: string;
    parent2_mobile?: string;
    teacher_prefix?: string;
    teacher_firstname?: string;
    teacher_surname?: string;
    teacher_mobile?: string;
  };
  success?: boolean;
}

interface Auth {
  user: UserData | null;
  signin: (idcard: string, date: string | undefined) => Promise<void>;
  signout: () => void;
  loading: boolean;
}
  
export const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const auth = useContext<Auth | null>(AuthContext);

  if (!auth || !auth.user) {
    auth?.signout();
    return <Navigate to="/register" />;
  }
  return <>{children}</>;
};
