const TestStatusCard = ({text, date}: {text:string, date:string}) => {
    return (
        <div className="bg-primary/20 border-l-8 border-primary p-4 ">
            {text}
            <br />
            {date}
        </div>
    )
}

export default TestStatusCard;