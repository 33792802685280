import { Ban, ClipboardCheck } from "lucide-react";
import { Link } from "react-router-dom";

const StatusBanner = ({
  status,
  option,
}: {
  status?: boolean;
  option?: string;
}) => {
  let title = "";
  let text : string | JSX.Element = "";
  switch (status) {
    case true:
      switch (option) {
        case "register":
          // title = "สถานะการเปิดรับสมัครระบบสอบปี 2566";
          title = "ระบบเปิดรับสมัครแล้ว สามารถคลิกที่นี่";
          text = 'ตรวจสอบสถานะ ผ่านการเข้าสู่ระบบ';
          break;
        case "test":
          title = "Testing is closed";
          text =
            "Testing is closed temporarily due to the high volume of requests. Please check back later.";
          break;
        case "payment":
          title = "ระบบชำระเงินเปิดให้บริการแล้ว";
          text = <>สามารถชำระเงินได้ที่หน้า <Link to={'/payment'}><strong>ชำระเงิน</strong></Link></>;
          break;
        case "examroom":
          title = "ตรวจสอบเลขประจำตัวผู้เข้าสอบและห้องสอบ";
          text = <>สามารถเข้าชมห้องสอบได้ที่หน้า <Link to={'/payment'}><strong>รายละเอียดห้องสอบ</strong></Link></>;
          break;
        case "examcard":
          title = "พิมพ์บัตรประจำตัวผู้เข้าสอบ";
          text = <>สามารถพิมพ์บัตรประจำตัวผู้เข้าสอบได้ที่หน้า <Link to={'/payment'}><strong>รายละเอียดห้องสอบ</strong></Link></>;
          break;
        case "scoreannounce":
            title = "ประกาศผลคะแนนของผู้เข้าสอบ";
            text = <>สามารถตรวจสอบคะแนนสอบได้ที่หน้า <Link to={'/announcement'}><strong>ประกาศผลสอบ</strong></Link></>;
            break;
        default:
          title = "Unknown";
          text = "Unknown";
          break;
      }
      break;
    case false:
      switch (option) {
        case "register":
          title = "สถานะการเปิดรับสมัครระบบสอบปี 2566";
          text = "ขณะนี้ระบบยังไม่เปิดรับสมัคร";
          break;
        case "test":
          title = "Testing is closed";
          text =
            "Testing is closed temporarily due to the high volume of requests. Please check back later.";
          break;
        case "payment":
          title = "ระบบชำระเงินยังไม่เปิดให้บริการ";
          text = "ระบบชำระเงินยังไม่เปิดให้บริการ กรุณาตรวจสอบใหม่อีกครั้งในภายหลัง";
          break;
        case "examroom":
          title = "รอประกาศห้องสอบ";
          text = "ประกาศห้องสอบจะปรากฎให้ทราบในภายหลัง";
          break;
        default:
          title = "Unknown";
          text = "Unknown";
          break;
      }
  }

  return (
    <div
      className={`rounded-md border border-black w-full p-4 ${
        status == false ? `bg-red-200` : `bg-sky-200`
      }`}
    >
      <div className="flex gap-4">
        {status == false ? (
          <span className="text-red-500 w-12">
            <Ban className="h-full w-full" />
          </span>
        ) : (
          <span className="text-sky-500 w-12">
            <ClipboardCheck className="h-full w-full" />
          </span>
        )}
        <div className="status-banner__content__text">
          <h2 className="font-bold text-lg">{title}</h2>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default StatusBanner;
