import axfetch from "../axfetch";

export const getExamResult = () => {
    return axfetch.get(`/api/examdata`, {
        withCredentials: true,
    });
};

export const getExamResultPdf = (exam_id: number) => {
    return axfetch.get(`/api/pdf/score/${exam_id}`, {
        withCredentials: true,
        responseType: "blob"
    });
};
