import { useState } from "react";
import InputMask from "react-input-mask";

const NationalIDInput = ({
  value,
  onChange,
}: {
  value: string | undefined;
  onChange: (id: string, valid: boolean) => void;
}) => {
  const [valid, setValid] = useState(true);

  const isIDValid = (id: string) => {
    if (id == "") {
      return true;
    }
    let sum = 0;
    if (id.length != 13) {
      setValid(false);
      return false;
    }
    // STEP 1 - get only first 12 digits
    for (let i = 0; i < 12; i++) {
      // STEP 2 - multiply each digit with each index (reverse)
      // STEP 3 - sum multiply value together
      sum += parseInt(id.charAt(i)) * (13 - i);
    }
    // STEP 4 - mod sum with 11
    const mod = sum % 11;
    // STEP 5 - subtract 11 with mod, then mod 10 to get unit
    const check = (11 - mod) % 10;
    // STEP 6 - if check is match the digit 13th is correct
    setValid(check == parseInt(id.charAt(12)));
    return check == parseInt(id.charAt(12));
  };

  return (
    <div className="w-full">
      <InputMask
        mask="9-9999-99999-99-9"
        aria-required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          let id = e.target.value;
          id = id.replace(/-/g, "");
          id = id.replace(/_/g, "");
          onChange(id, isIDValid(id));
        }}
        className={`border-black border-2 ${
          !valid &&
          "border-secondary text-secondary focus:outline-none focus:border-secondary"
        } text-center rounded-md h-10 px-4 py-2 w-full`}
        id="citizenid"
        type="text"
        name="citizenid"
        placeholder="9-9999-99999-99-9"
        title="กรอกเลขบัตรประจำตัวประชาชน"
        aria-labelledby="citizenid"
        value={value}
        required
      />
      {!valid && (
        <p className="text-secondary">กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง</p>
      )}
    </div>
  );
};

export default NationalIDInput;
