type ContainerProps = {
    children: React.ReactNode;
};

const News = (props: ContainerProps) => {
    return (
        <div className="">
            {props.children}
        </div>
    )
}

export default News;
