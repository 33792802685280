import axfetch from "../axfetch";

export const getSubdistrict = (province: string | undefined) => {
    return axfetch.get(
        `/api/getsubdistrict`,
        {
          params: {
            provinces: province
          }
        }
      );
}

export const getProvince = (subDistrictId: number) => {
    return axfetch.get(
        `/api/getprovinces/id/${subDistrictId}`,
    );
}