import axfetch from "../axfetch";

export const getSchool = (school_name: string | undefined) => {
    return axfetch.get(
        `/api/searchschool`,
        {
          params: {
            school_name: school_name
          }
        }
      );
}