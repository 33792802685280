import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"


const Dropdown = ({ id, name, placeHolder, onChange, obj, style, isRequire }:
    {
        id:string,
        name:string,
        placeHolder: string,
        onChange: (value: string) => void,
        obj: {
            key: string,
            value: string,
        }[],
        style: string,
        isRequire: boolean,
    }) => {
    return (
        <Select required={isRequire} onValueChange={onChange}>
            <SelectTrigger name={name} title={name} id={id} className={`w-full border-2 border-black ${style}`}>
                <SelectValue placeholder={placeHolder} />
            </SelectTrigger>
            <SelectContent>
                {
                    obj.map((inner: {
                        key: string,
                        value: string,
                    }) => {
                        return (
                            <SelectItem key={inner.key} value={inner.key}>{inner.value}</SelectItem>
                        )
                    })
                }
            </SelectContent>
        </Select>
    )
}

export default Dropdown