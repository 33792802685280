import { getProfile } from "@/lib/api/getProfile";
import { login } from "@/lib/api/login";
import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";

interface UserData {
  data?: {
    id?: number;
    student_identityid_type?: null;
    student_identityid?: string;
    student_prefix?: string;
    student_firstname?: string;
    student_surname?: string;
    student_birthday?: string;
    student_level?: string;
    student_school?: string;
    student_school_subdistrict?: number;
    student_house_no?: string;
    student_village_no?: string;
    student_addr_alley?: string;
    student_addr_lane?: string;
    student_addr_street?: string;
    student_addr_subdistrict?: number;
    student_mobile?: string;
    parent1_prefix?: string;
    parent1_firstname?: string;
    parent1_surname?: string;
    parent1_relationship?: string;
    parent1_mobile?: string;
    parent2_prefix?: string;
    parent2_firstname?: string;
    parent2_surname?: string;
    parent2_relationship?: string;
    parent2_mobile?: string;
    teacher_prefix?: string;
    teacher_firstname?: string;
    teacher_surname?: string;
    teacher_mobile?: string;
  };
  success?: boolean;
}

interface Auth {
  user: UserData | null;
  signin: (idcard: string, date: string | undefined) => Promise<void>;
  signout: () => void;
  loading: boolean;
}

interface Props {
  children?: React.ReactNode;
}

const AuthContext = createContext<Auth | null>(null);

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<object | null>({});
  const [loading, setLoading] = useState(false);

  const checkAccessToken = () => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      return true;
    } else {
      return false;
    }
  };

  const getUser = async () => {
    setLoading(true);
    await getProfile()
      .then((res) => {
        if (res.data?.success == true) {
          setUser(res.data);
        } else {
          setUser(null);
        }
      })
      .catch(() => {
        setUser(null);
      });
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      (await checkAccessToken()) ? getUser() : setUser(null);
    })();
  }, []);

  const signin = async (idcard: string, date: string | undefined) => {
    await login(idcard, date).then((res) => {
      Cookies.set("accessToken", res.data?.accessToken);
    });
    await getProfile().then((res) => {
      if (res.data?.success == true) {
        setUser(res.data);
      }
    });
  };

  const signout = () => {
    Cookies.remove("accessToken");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, signin, signout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
