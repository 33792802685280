import Header from "@/components/ui/header";
import NewsCard from "@/components/ui/newsCard";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getContent } from "@/lib/api/getWebpage";
import { Loader } from "lucide-react";

const Advertise = () => {
  const { data: news, isLoading } = useQuery(["news"], getContent, {
    staleTime: 1000 * 60 * 60 * 24,
  });

  return (
    <div>
      <div className="relative">
        <Header text="ประชาสัมพันธ์ | LASTEST NEWS" />
        {/* <div className="hidden lg:block absolute top-4 left-72 text-black text-xs">
          คลิกเพื่ออ่านเพิ่มเติม
        </div> */}
      </div>
      <div className="mt-4 flex flex-col gap-4 min-h-[50px] overflow-y-auto">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Loader
              size={64}
              className="motion-safe:animate-spin text-orange-300"
            />
          </div>
        ) : (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          news?.data.announcement.map((input: any) => {
            return (
              <Link key={input.id} to={input.href} target="_blank">
                <NewsCard text={input.content} />
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Advertise;
