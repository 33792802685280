import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  //   DialogTrigger,
} from "@/components/ui/dialog";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { getProvince } from "@/lib/api/getAddress";
import { useQueries } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";

interface Props {
  //   children: React.ReactNode;
  dataStudent: Student;
  dataTeacher: Teacher;
  dataParents: Array<Parent>;
  onOpenChange: ((open: boolean) => void) | undefined;
  open: boolean;
  onClick: () => void;
}

interface Student {
  prefix: string;
  firstName: string;
  lastName: string;
  nationalID: string;
  DOB: Date | undefined;
  phone: string;
  level: string;
  // address: string,
  house_no: string | undefined;
  village_no: string | undefined;
  addr_alley: string | undefined;
  addr_lane: string | undefined;
  addr_street: string | undefined;
  province: string;
  district: string;
  subDistrict: string;
  zipCode: string;
  schoolID: string;
  schoolProvince: string | undefined;
  schoolDistrict: string | undefined;
  schoolSubDistrict: string;
}

interface Parent {
  prefix: string;
  firstName: string;
  lastName: string;
  relation: string;
  phone: string;
}

interface Teacher {
  prefix: string;
  firstName: string;
  lastName: string;
  phone: string;
}

const SumModal: React.FC<Props> = ({
  dataStudent,
  dataTeacher,
  dataParents,
  open,
  onOpenChange,
  onClick,
}) => {
  //   const { data: address } = useQuery(
  //     ["province", Number(dataStudent.subDistrict)],
  //     () => getProvince(Number(dataStudent.subDistrict))
  //   );

  //const { data: address } = useQuery(
  //     ["province", Number(dataStudent.subDistrict)],
  //     () => getProvince(Number(dataStudent.subDistrict))
  //   );

  const [address, schoolAddress] = useQueries({
    queries: [
      {
        queryKey: ["province", Number(dataStudent.subDistrict)],
        queryFn: () => getProvince(Number(dataStudent.subDistrict)),
      },
      {
        queryKey: ["province", Number(dataStudent.schoolSubDistrict)],
        queryFn: () => getProvince(Number(dataStudent.schoolSubDistrict)),
      },
    ],
  });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {/* <DialogTrigger asChild>
        {children}
    </DialogTrigger> */}
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>สรุปข้อมูล</DialogTitle>
          <DialogDescription>กรุณาตรวจสอบข้อมูลก่อนยืนยัน</DialogDescription>
        </DialogHeader>
        <div>
          <p className="font-bold p-2 bg-primary/30 rounded w-fit mx-2 mb-2">
            ข้อมูลผู้เข้าสอบ
          </p>
          <p>
            <span className="font-bold">ชื่อจริง</span>{" "}
            {dataStudent.prefix +
              dataStudent.firstName +
              " " +
              dataStudent.lastName}
          </p>
          <p>
            <span className="font-bold">เลขประจำตัวประชาชน</span>{" "}
            {dataStudent.nationalID}
          </p>
          <p>
            <span className="font-bold">วันเกิด</span>{" "}
            {dataStudent.DOB?.toLocaleDateString("th-TH", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
          {!dataStudent.phone ? (
            <></>
          ) : (
            <p>
              <span className="font-bold">เบอร์โทรศัพท์</span>{" "}
              {dataStudent.phone}
            </p>
          )}
          <p>
            <span className="font-bold">ระดับชั้น</span>{" "}
            {dataStudent.level.replace("level", "ประถมศึกษาปีที่ ")}
          </p>
          <p>
            <span className="font-bold">ที่อยู่</span>{" "}
            {dataStudent.house_no +
              ", หมู่ " +
              dataStudent.village_no +
              " ถนน" +
              dataStudent.addr_street +
              " ตำบล" +
              address.data?.data?.data?.[0]?.name_th +
              " อำเภอ" +
              dataStudent.district +
              " จังหวัด" +
              address.data?.data?.data?.[0]?.districts?.provinces?.name_th +
              " " +
              " รหัสไปรษณีย์ " +
              address.data?.data?.data?.[0]?.zip_code}
          </p>
          <p className="font-bold p-2 bg-primary/30 rounded w-fit m-2">
            ข้อมูลสถานศึกษา
          </p>
          <p>
            <span className="font-bold">ปัจจุบันศึกษาอยู่</span>{" "}
            {dataStudent.schoolID}
          </p>
          <p>
            <span className="font-bold">ที่อยู่โรงเรียน</span>{" "}
            {"ตำบล" +
              schoolAddress.data?.data?.data?.[0]?.name_th +
              " อำเภอ" +
              dataStudent.schoolDistrict +
              " จังหวัด" +
              schoolAddress.data?.data?.data?.[0]?.districts?.provinces
                ?.name_th}
          </p>
          <p className="font-bold p-2 bg-primary/30 rounded w-fit m-2">
            ข้อมูลผู้ปกครอง (1)
          </p>
          <p>
            <span className="font-bold">ชื่อจริง</span>{" "}
            {dataParents[0].prefix +
              dataParents[0].firstName +
              " " +
              dataParents[0].lastName}
          </p>
          <p>
            <span className="font-bold">ความสัมพันธ์</span>{" "}
            {dataParents[0].relation}
          </p>
          <p>
            <span className="font-bold">เบอร์โทรศัพท์</span>{" "}
            {dataParents[0].phone}
          </p>

          {dataParents[1].firstName !== "" &&
          dataParents[1].lastName !== "" &&
          dataParents[1].relation !== "" ? (
            <>
              <p className="font-bold p-2 bg-primary/30 rounded w-fit m-2">
                ข้อมูลผู้ปกครอง (2)
              </p>
              <p>
                <span className="font-bold">ชื่อจริง</span>{" "}
                {dataParents[1].prefix +
                  dataParents[1].firstName +
                  " " +
                  dataParents[1].lastName}
              </p>
              <p>
                <span className="font-bold">ความสัมพันธ์</span>{" "}
                {dataParents[1].relation}
              </p>
              <p>
                <span className="font-bold">เบอร์โทรศัพท์</span>{" "}
                {dataParents[1].phone}
              </p>
            </>
          ) : (
            <></>
          )}
          <p className="font-bold p-2 bg-primary/30 rounded w-fit m-2">
            ข้อมูลครูที่ปรึกษา
          </p>
          <p>
            <span className="font-bold">ชื่อจริง</span>{" "}
            {dataTeacher.prefix +
              dataTeacher.firstName +
              " " +
              dataTeacher.lastName}
          </p>
          <p>
            <span className="font-bold">เบอร์โทรศัพท์</span> {dataTeacher.phone}
          </p>
        </div>
        <DialogFooter>
          <DialogPrimitive.Close aria-label="Close">
            <Button type="button" className="bg-orange-500 hover:bg-orange-300 active:bg-orange-400">แก้ไข</Button>
          </DialogPrimitive.Close>
          <Button onClick={onClick}>ยืนยัน</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SumModal;
