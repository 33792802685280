const NewsCard = ({ text }: { text: string }) => {
    return (
        <div className="flex gap-2 items-center bg-primary/20 border-l-8 border-primary p-4 hover:shadow-lg hover:opacity-80 hover:cursor-pointer">
            {text}
            <svg
                className="min-w-[20px] min-h-[20px]"
                viewBox="0 0 24 24"
                fill="currentColor"
                height="1em"
                width="1em"
            >
                <path d="M14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3m-2 16H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7h-2v7z" />
            </svg>
        </div>
    )
}

export default NewsCard;