import axfetch from "../axfetch";

export const getRangeRegister: () => void = () => {
  return axfetch.get(`/api/timeswitch/student_register`);
};

export const getRangeEditProfile: () => void = () => {
  return axfetch.get(`/api/timeswitch/student_edit_profile`);
};

export const getRangePayment: () => void = () => {
  return axfetch.get(`/api/timeswitch/student_payment`);
};

export const getRangeDownloadExamCard: () => void = () => {
  return axfetch.get(`/api/timeswitch/student_download_examcard`);
};

export const getRangeShowExamRoom: () => void = () => {
  return axfetch.get(`/api/timeswitch/student_show_examroom`);
};

export const getRangeShowScoreResult: () => void = () => {
  // student_score_announce
  return axfetch.get(`/api/timeswitch/student_score_announce`);
}
