import axios from "axios";

const axfetch = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL,
})

axfetch.interceptors.request.use(config => {
    axfetch.defaults.withCredentials = true;
    return config;
})

export default axfetch;