import axfetch from "../axfetch";

interface Transaction {
  transaction_amount: number;
  transaction_url: string;
  transaction_date: Date | undefined;
  transaction_time: string;
  transaction_ref: string;
  transaction_id: number;
}

export const postTransaction = (data: Transaction) => {

  return axfetch.post(
    `/api/transaction/update`,
    {
      transaction_id: data.transaction_id,
      transaction_amount: String(data.transaction_amount),
      transaction_url: data.transaction_url,
      transaction_date:
        new Date(data.transaction_date?.toLocaleString().substring(0, 11) +
        data.transaction_time),
      transaction_ref: data.transaction_ref,
    },
    {
      withCredentials: true,
    }
  );
};
